import { template as template_75c9539c82964d00bf0ff37431228362 } from "@ember/template-compiler";
const FKText = template_75c9539c82964d00bf0ff37431228362(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
