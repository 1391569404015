import { template as template_e9d6a8902ac44339ae4b9518221191cc } from "@ember/template-compiler";
const SidebarSectionMessage = template_e9d6a8902ac44339ae4b9518221191cc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
