import { template as template_779b01749f1d44a6bd286681c31abdaf } from "@ember/template-compiler";
const FKLabel = template_779b01749f1d44a6bd286681c31abdaf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
